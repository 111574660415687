<template>
	<div class="grid">
		<div class="col-12"> 
            <div class="botoes_topo">
                <Button @click="importarCadastrar()" label="Importar Pedidos" class="btn_success" :badge="qtd_novos.toString()" style="float: right;"/>
                <div style="clear:both"></div>
            </div>
            <div class="card">
                <TabView @tab-change="onTabChange">
                    
                    <TabPanel header="Todos os Pedidos" >
                        
                    </TabPanel>
                    <TabPanel header="Aprovados">
                        
                    </TabPanel>
                    <TabPanel header="Faturados">
                    </TabPanel>
                    <TabPanel header="Enviados">
                    </TabPanel>
                    <TabPanel header="Entregues">
                    </TabPanel>
                    <TabPanel header="Cancelados">
                    </TabPanel>
                </TabView>
                <Loading v-if="loading" />
                <table class="p-datatable-table" style="overflow: scroll; display: block;overflow-x: auto;width:100%" role="rowgroup"
                    v-if="!loading">
                    <thead class="p-datatable-thead" role="rowgroup">
                        <tr role="row">
                            <th v-for="(nomeColuna, index) in nomeColunas" :key="index" class="p-sortable-column" tabindex="0"
                                role="cell">
                                <div class="p-column-header-content">
                                    <span v-html="nomeColuna['nome']" class="p-column-title"></span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="p-datatable-tbody" role="rowgroup">
                        <tr>
                            <td style="width:3%"></td>
                            <td style="width:7%"></td>
                            <td style="width:22%"></td>
                            <td style="width:12%"></td>
                            <td style="width:8%"></td>
                            <td style="width:20%"></td>
                            <td style="width:7%"></td>
                            <td style="width:7%"></td>
                            <td style="width:12%"></td>
                        </tr>
                        <tr v-for="(pedido, index) in state.pedidos" :key="index" class="" role="row">
                            <td style="width:3%">{{ pedido['id'] }}</td>
                            <td style="width:7%">{{ pedido['criado_em']}}</td>
                            <td style="width:22%">{{ pedido['nome_cliente'] }}</td>
                            <td style="width:12%">{{ pedido['cpf_cliente'] }}</td>
                            <td style="width:8%">{{ pedido['valor_total'] }}</td>
                            <td style="width:20%">{{ pedido['codigo_api'] }}</td>
                            <td style="width:7%"><img class="img_marketplace" :src="pedido['imagem']" /></td>
                            <td style="width:12%">
                                <div style="display: inline-block;">
                                    <div v-if="pedido['status'] == 'APROVADO'">
                                        <button type="button" @click="toggle($event,pedido['status'],pedido.id)"
                                            class="btn_fundo_branco">
                                            <img src="\images\icones\icone_aprovados.png" class="icone">
                                        </button>
                                    </div>
                                    <div v-if="pedido['status'] == 'FATURADO'">
                                        <button type="button" @click="toggle($event,pedido['status'],pedido.id)"
                                            class="btn_fundo_branco">
                                            <img src="\images\icones\icone_faturados.png" class="icone">
                                        </button>
                                    </div>
                                    <div v-if="pedido['status'] == 'TRANSPORTADORA'">
                                        <button type="button" @click="toggle($event,pedido['status'],pedido.id)"
                                            class="btn_fundo_branco">
                                            <img src="\images\icones\icone_enviado.png" class="icone">
                                        </button>
                                    </div>
                                    <div v-if="pedido['status'] == 'ENTREGUE'">
                                        <button type="button" @click="toggle($event,pedido['status'],pedido.id)"
                                            class="btn_fundo_branco">
                                            <img src="\images\icones\icone_entregues.png" class="icone">
                                        </button>
                                    </div>
                                    <div v-if="pedido['status'] == 'CANCELADO'">
                                        <button type="button" @click="toggle($event,pedido['status'],pedido.id)"
                                            class="btn_fundo_branco">
                                            <img src="\images\icones\icone_cancelados.png" class="icone">
                                        </button>
                                    </div>
                                    <Menu ref="menu" :model="items" :popup="true" />
                                </div>
                                <button style="display: inline-block;" class="p-button p-component p-button-icon-only p-button-text"
                                    @click="carregarPedido(pedido.id)">
                                    <i class="pi pi-search p-button-icon"></i>
                                </button>
                                <button style="display: inline-block;" class="p-button p-component p-button-icon-only p-button-text"
                                    @click="carregarProduto(produto.id)">
                                    <i class="pi pi-print"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                
                </table>
                <div v-if="quantidadePaginas > 1" class="p-paginator p-component p-paginator-bottom" style="width:100%">
                    <div style="padding: 5px;cursor: pointer;" @click="primeiraPagina()"> Primeira </div><div style="padding: 5px;cursor: pointer;" @click="anteriorPagina()">Anterior</div>
                    <div style="padding: 5px;cursor: pointer;" v-for="pagina in quantidadePaginas" :key="pagina" @click="paginaAtual(pagina)">{{ pagina }}</div>
                    <div style="padding: 5px;cursor: pointer;" @click="proximaPagina()">Próxima</div>
                    <div style="padding: 5px;cursor: pointer;" @click="ultimaPagina()">Ultima</div>
                </div>
            </div>
		</div>
	</div>
    <Dialog header="Importar Pedidos" v-model:visible="displayImportarCadastrar" >
        <Loading v-if="loadingImportarPedidos" style="margin-top:50px"/>
        <div class="corpo_importar_pedidos" >
            <div v-if="!loadingImportarPedidos">
                <div style="text-align:center">
                <div v-for="(marketplace, index) in marketplaces" :key="index">
                    <div class="flex">
                        <button class="marketplace" @click="importarPedidos(marketplace.nome_hub)">
                            <img :src="marketplace.imagem" class="img_marketplace" /> 
                            <div class="nome_marketplace">{{marketplace.nome}}</div>
                        </button>
                    </div>
                </div>
                </div>
                <div style="text-align:center; width:100%; margin-top:10px;">
                    <Button label="Importar Todos" class="btn_success" @click="importarTodos()"/>
                    <Button label="Fechar" class="btn_cancel" @click="fecharModalImportar()"/> 
                </div>
            </div>
        </div>
    </Dialog>

    <Dialog header="Status" v-model:visible="displayStatus" >
        <div class="flex flex-column" style="width:500px">
            <div class="grid">
                <div class="col-12">
                    <select v-model="selectedStatus" @change="alterarStatus($event.target.selectedOptions[0].value)">
                        <option v-if="selectedStatus == 'APROVADO'" value="APROVADO">Aprovado</option>
                        <option v-if="selectedStatus == 'APROVADO'|| selectedStatus == 'FATURADO'" value="FATURADO">Faturado</option>
                        <option v-if="selectedStatus == 'FATURADO'|| selectedStatus == 'TRANSPORTADORA'" value="TRANSPORTADORA">Enviado</option>
                        <option v-if="selectedStatus == 'TRANSPORTADORA'|| selectedStatus == 'ENTREGUE'" value="ENTREGUE">Entregue</option>
                        <option value="CANCELADO">Cancelado</option>
                        <option value="PROBLEMA_ENTREGA">Problema na Entrega</option>

                    </select>
                </div>
            </div>
            
            <div class="grid" v-if="selectedStatus && selectedStatus == 'FATURADO'">
                <div class="col-12">
                    <label>XML da Nota Fiscal</label>
                    <div class="inputFile" style="margin-top:10px; height:33px !important">
                        <div style="position:absolute; left: 50%; margin-left: -60px; margin-top: 7px">
                            Selecionar Arquivo
                        </div>
                        <div style="border: #000 solid 1px">
                            <input type="file" style="width:100%" @change="carregarXML">
                        </div>
                    </div>
                </div>

                <div class="col-12 ">
                    <label>Chave Nota Fiscal</label>
                    <InputText v-model="state.chave_nota_fiscal" style="width:100%; margin-left:0"/>
                </div>

                <div class="col-6 ">
                    <label>Série Nota Fiscal</label>
                    <InputText v-model="state.serie_nota_fiscal" style="width:100%; margin-left:0"/>
                </div>
                <div class="col-6 ">
                    <label>N da Nota Fiscal</label>
                    <InputText v-model="state.numero_nota_fiscal" style="width:100%; margin-left:0"/>
                </div>

                
                <div class="col-6 ">
                    <label>Data da Emissão</label>
                    <InputText v-model="state.data_emissao_nota" style="width:100%; margin-left:0" />
                </div>
                <div class="col-6 ">
                    <label>Hora da Emissão</label>
                    <InputText v-model="state.hora_emissao_nota" style="width:100%; margin-left:0"/>
                </div>
            </div>
            <div class="grid" v-if="selectedStatus && selectedStatus == 'TRANSPORTADORA'">
                <div class="col-12 ">
                    <label>Código Rastreio</label>
                    <InputText v-model="codigo_rastreio" style="width:100%; margin-left:0" />
                </div>
                <div class="col-12 ">
                    <label>Transportadora</label>
                    <select  @change="carregarMetodoTransporte($event.target.selectedOptions[0].value,$event.target.textContent)">
                        <option></option>
                        <option v-for="(transportadora, index) in transportadoras" :key="index" :value="transportadora.id">{{transportadora.nome}}</option>
                    </select>
                </div>
                <div class="col-12 ">
                    <label>Método</label>
                    <select v-model="metodo_envio" >
                        <option v-for="(metodo, index) in metodos_transporte" :key="index" :value="metodo.nome">{{metodo.nome}}</option>
                    </select>
                </div>  
                <div class="col-6">
                    <label>Data Envio</label>
                    <InputText v-model="data_envio" style="width:100%; margin-left:0"  />
                </div>  
                <div class="col-6">
                    <label>Hora Envio</label>
                    <InputText v-model="hora_envio" style="width:100%; margin-left:0" />
                </div>  
            </div>
            <div class="grid" v-if="selectedStatus && selectedStatus == 'ENTREGUE'">
                <div class="col-12">
                    <label>Data da Entrega</label>
                    <InputText v-model="data_entrega" style="width:100%; margin-left:0" />
                </div>                  
            </div>
            <div class="grid" v-if="selectedStatus && selectedStatus == 'PROBLEMA_ENTREGA'">
                <div class="col-12">
                    <label>Data da Ocorrencia </label>
                    <InputText v-model="data_ocorrencia" style="width:100%; margin-left:0" />
                </div>
                <div class="col-12 ">
                    <label>Observação</label>
                    <InputText v-model="observacao_ocorrencia" style="width:100%; margin-left:0" />
                </div>                  
            </div>

            <div style="text-align:center; width:100%; margin-top:10px;">
                <Button class="btn_success" label="Salvar Status" @click="salvarStatus(this.pedido_selecionado)"/>
                <Button class="btn_cancel" label="Fechar" @click="fecharStatus()" style="margin-right:5px"/> 
            </div>
        </div>
    </Dialog>

    <Toast position="top-left" />

</template>

<script>

import Api from '../service/api';
import Loading from '../components/Loading.vue';
import { reactive } from 'vue';
	export default {
    setup(){
        const state = reactive({
            chave_nota_fiscal:'',
            serie_nota_fiscal:'',
            numero_nota_fiscal:'',
            data_emissao_nota:'',
            hora_emissao_nota: '',
            pedidos:[]
        })
        return{
            state
        }
    },
    data() {
        return {
            msg_fim_sessao:'A sessão expirou. Faça Login para voltar ao Hub.',
			msg_erro:'Erro ao se comunicar com o servidor. Verifique sua internet e tente novamente. Caso o problema persista entre em contato com o suporte.',
            empresa_id:0,
            xmlItems:[],

            api: "",
            loading: true,
            transportadoras:[],
            metodos_transporte:[],
            loadingImportarPedidos:false,
            pedidos: null,
            qtd_novos:0,
            pagina: "1",
            quantidadePorPagina: "10",
            primeiroItemDaPagina: 1,
            ordenar: "id",
            tipoOrdenar: "DESC",
            totalLinhas: null,
            displayImportarCadastrar:false,
            displayStatus:false,
            qtdPedidosSkyhub:0,
            url:'',
            marketplaces:[],
            quantidades: ["10", "20", "50"],
            nomeColunas: [
                { nome: "N", value: "id" },
                { nome: "Data", value: "criado_em" },
                { nome: "Cliente", value: "nome_cliente" },
                { nome: "CPF/CNPJ", value: "cpf_cliente" },
                { nome: "Valor", value: "valor_total" },
                { nome: "Cod. Pedido", value: "codigo_api" },
                { nome: "Integração", value: "imagem" },
                { nome: "Ação", value:"acao"}
            ],
            produtosAdicionar: [
                { id: "" },
                { descricao: "" },
                { sku: "" },
                { preco_normal: "" },
                { preco_desconto: "" },
                { altura: "" },
                { largura: "" },
                { profundidade: "" },
                { peso: "" },
                { estoque: "" },
                { video: "" },
            ],
            selectedStatus: null,
            pedido_selecionado:0,
            status_selecionado:"",
            items: [
				{
					label: 'Alterar Status',
					icon: 'pi pi-pencil',
					command: () => {
                        this.modalStatus()
					}
				}/*,
				{
					label: 'Vue Website',
					icon: 'pi pi-external-link',
					command: () => {
						window.location.href = 'https://vuejs.org/'
					}
				},
				{
					label: 'Upload',
					icon: 'pi pi-upload',
                    to: '/fileupload'
				}*/
			],
            mostrarAdicionar: false,
        };
    },
    produtosService: null,
    quantidadePaginas: 1,
    created() {
        this.empresa = this.$storage.getStorageSync("empresa")
        this.token = this.$storage.getStorageSync("jwt")
        this.empresa_id = this.empresa.id
        this.api = new Api();
        this.api.qtdPedidos(this.token,this.empresa_id).then(data => { this.totalLinhas = data.data.quantidade 
        }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
    },
    mounted() {
        this.listarTransportadoras();
        this.quantidadePedidosNovos();
        this.listarPedidos();  
        this.novosPedidosSkyhub();
    },
    methods: {
        onTabChange(event) {
            if (event.index == 0) {
                //Aprovados
                this.listarPedidos()
            }else if (event.index == 1) {
                //Aprovados
                this.buscarPorStatus('APROVADO')
            } else if (event.index == 2) {
                //Faturados
                this.buscarPorStatus('FATURADO')
            } else if (event.index == 3) {
                //Enviados
                this.buscarPorStatus('ENVIADO')
            } else if (event.index == 4) {
                //Entregues
                this.buscarPorStatus('ENTREGUE')
            } else if (event.index == 5) {
                //Cancelados
                this.buscarPorStatus('CANCELADO')
            }
            console.log(event)
        },
        toggle(event,status,pedido_id) {
            this.status_selecionado = status
            var nome_status = status
            var obj_status = {
					label: nome_status,
					items:[],
				};
            

            if(this.items.length >= 2){
                this.items.shift();
            }
            this.items.unshift(obj_status)
            this.$refs.menu.toggle(event);

            this.pedido_selecionado = pedido_id
            
          //  :model="items"
        //    this.$refs.menu.model(this.items);
        },

        carregarXML(event){
            const element = event.target.files[0];
            var formAtualizar = new FormData();
            formAtualizar.append("xml",element);

            this.api.enviarXML(this.token,formAtualizar).then(data => {
                this.state.chave_nota_fiscal  = data.data.chave
                this.state.serie_nota_fiscal  = data.data.serie
                this.state.numero_nota_fiscal = data.data.numero
                this.state.data_emissao_nota  = data.data.data
                this.state.hora_emissao_nota = data.data.hora
            });
        
        },

        alterarStatus(status){
            
            var data = new Date(),
            dia  = data.getDate().toString(),
            diaF = (dia.length == 1) ? '0'+dia : dia,
            mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
            mesF = (mes.length == 1) ? '0'+mes : mes,
            anoF = data.getFullYear();
            var data_atual = diaF+"/"+mesF+"/"+anoF;
            //return diaF+"/"+mesF+"/"+anoF;

            this.data_emissao_nota = data_atual

            
//this.selectedStatus = status;
           /* if(status == "FATURADO"){   
            
            }else if(status == "TRANSPORTADORA"){

            }else if(status == "ENTREGUE"){

            }else if(status == "CANCELADO"){

            }else if(status == "PROBLEMA_ENTREGA"){

            }*/           

            console.log('aqui'+status)
        },
        carregarMetodoTransporte(transportadora_id, transportadora_nome){
            this.transportadora = transportadora_nome
            this.api.listarMetodosTransportePorTransportadoras(this.token,transportadora_id).then(data => {
                this.url = data.data.transportadora.url
                this.metodos_transporte = data.data.metodos_transporte
                this.loading = false 
                }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
            
            console.log(transportadora_id)
        },
        listarTransportadoras() {
            this.loading = true;
            this.api.listarTransportadoras(this.token).then(data => {
                console.log(data.data.transportadoras)
                this.transportadoras = data.data.transportadoras
                this.loading = false 
                }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },
       listarPedidos(pagina = this.pagina, quantidadePorPagina = this.quantidadePorPagina, ordenar = this.ordenar, tipoOrdenar = this.tipoOrdenar) {
            this.loading = true;
            this.api.listarPedidos(this.token,this.empresa_id,pagina, quantidadePorPagina, ordenar, tipoOrdenar).then(data => {
                this.state.pedidos = data.data.resposta
                this.quantidadePaginas = parseInt(this.totalLinhas) / parseInt(this.quantidadePorPagina)
                this.quantidadePaginas = Math.ceil(this.quantidadePaginas)
                this.loading = false;
                }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },

        listarIntegracoes() {
            this.loadingImportarPedidos = true;
            this.api.listarIntegracoes(this.token,this.empresa_id).then(data => {
                this.marketplaces = data.data
                this.loadingImportarPedidos = false;
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },

        importarCadastrar(){
            this.displayImportarCadastrar = true;
            this.listarIntegracoes();
            
        },

        novosPedidosSkyhub(){
    //        var temPedidos;
            this.api.buscarNovosPedidosSkyhub().then(data => {
                this.qtdPedidosSkyhub = data.data.total;
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },
        fecharModalImportar(){
            this.displayImportarCadastrar = false;
        },

        importarPedidos(marketplace){
            
            switch (marketplace) {
                case 'Skyhub':
                    this.api.importarNovosPedidos(this.token,this.empresa_id,marketplace).then(data => {
                        console.log(data);
                        this.displayStatus = false;
                    //    this.qtdPedidosSkyhub = data.data.total;
                        this.listarPedidos();

                    }).catch((error) => {
                    if(error.response.status == 401){
                        alert(this.msg_fim_sessao);
                        this.$router.push('/login');	
                    }else{
                        alert(this.msg_erro);
                    }
                     }); 
                    break;
                    case 'Integracommerce':
                        this.api.importarNovosPedidos(this.token,this.empresa_id,marketplace).then(data => {
                            this.qtdPedidosSkyhub = data.data.total;
                        }).catch((error) => {
                        if(error.response.status == 401){
                            alert(this.msg_fim_sessao);
                            this.$router.push('/login');	
                        }else{
                            alert(this.msg_erro);
                        }
                    }); 
                    break;
                    case 'MadeiraMadeira':
                        this.api.importarNovosPedidos(this.token,this.empresa_id,marketplace).then(data => {
                            this.qtdPedidosSkyhub = data.data.total;
                        }).catch((error) => {
                    if(error.response.status == 401){
                        alert(this.msg_fim_sessao);
                        this.$router.push('/login');	
                    }else{
                        alert(this.msg_erro);
                        }
                    }); 
                    break;
                case 'ML':
                    this.api.importarNovosPedidos(this.token,this.empresa_id,marketplace).then(data => {
                        this.qtdPedidosSkyhub = data.data.total;
                    }).catch((error) => {
                    if(error.response.status == 401){
                        alert(this.msg_fim_sessao);
                        this.$router.push('/login');	
                    }else{
                        alert(this.msg_erro);
                    }
                    }); 
                    break;
                default:
                    break;
            }
            this.displayStatus = false;
        },

        importarNovosPedidosSkyhub(){
            this.api.importarPedidos('Skyhub').then(data => {
                this.qtdPedidosSkyhub = data.data.total;
                this.listarPedidos();

            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },

        importarNovosPedidosIntegracommerce(){
            this.api.importarNovosPedidosIntegracommerce(1).then(data => {
                this.qtdPedidosSkyhub = data.data.total;
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },

        importarNovosPedidosMadeira(){
            this.api.importarNovosPedidosMadeira(1).then(data => {
                this.qtdPedidosSkyhub = data.data.total;
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },

        importarPedidosMercadoLivre(){
            this.api.importarNovosPedidosMercadoLivre(1).then(data => {
                this.qtdPedidosSkyhub = data.data.total;
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },

        importarTodos(){
            this.api.listarIntegracoes(this.token,this.empresa_id).then(data => {
                this.marketplaces = data.data
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
            this.marketplaces.forEach(element => {
                switch (element.nome) {
                    case 'Skyhub':
                        this.importarPedidos('Skyhub');
                        break;
                    case 'Integracommerce':
                        this.importarPedidos('Integracommerce');
                        break;
                    case 'MadeiraMadeira':
                        this.importarPedidos('Madeira');
                        break;
                    default:
                        break;
                }
            });
            this.listarPedidos();
        },

        quantidadePedidosNovos(){
            
            this.api.quantidadePedidosNovos(this.token, this.empresa_id).then(data => {
                this.qtd_novos = data.data
                this.listarPedidos();
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },

        modalStatus(){
            var data = new Date(),
            dia  = data.getDate().toString(),
            diaF = (dia.length == 1) ? '0'+dia : dia,
            mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
            mesF = (mes.length == 1) ? '0'+mes : mes,
            anoF = data.getFullYear();
            var data_atual = diaF+"/"+mesF+"/"+anoF;
            //return diaF+"/"+mesF+"/"+anoF;

            this.data_envio = data_atual
            this.data_entrega = data_atual
            this.data_ocorrencia = data_atual

            var hora = data.getHours();
            var minutos = data.getMinutes();

            var horario_atual = hora+':'+minutos

            this.hora_emissao_nota = horario_atual
            this.hora_envio = horario_atual

         
            this.selectedStatus = this.status_selecionado;
            this.displayStatus = true;
        },


        async salvarStatus(item_selecionado){
            if(this.selectedStatus == "FATURADO"){
                await this.api.alterarStatusPedidoFaturado(this.token,this.empresa_id,item_selecionado, this.state.numero_nota_fiscal, this.state.chave_nota_fiscal, this.state.data_emissao_nota, this.state.hora_emissao_nota)
            
            }else if(this.selectedStatus == "TRANSPORTADORA"){
                await this.api.alterarStatusPedidoTransportadora(this.token,this.empresa_id,item_selecionado, this.codigo_rastreio, this.transportadora, this.metodo_envio, this.url, this.data_envio, this.hora_envio)

            }else if(this.selectedStatus == "ENTREGUE"){
                await this.api.alterarStatusPedidoEntregue(this.token,this.empresa_id,item_selecionado,this.data_entrega)

            }else if(this.selectedStatus == "CANCELADO"){
                await this.api.alterarStatusPedidoCancelado(this.token,this.empresa_id,item_selecionado)

            }else if(this.selectedStatus == "PROBLEMA_ENTREGA"){
                await this.api.alterarStatusPedidoProblemaEntrega(this.token,this.empresa_id,item_selecionado, this.data_ocorrencia, this.observacao_ocorrencia)
            }                  
            this.fecharStatus();
            this.listarPedidos();

        },

        carregarPedido(pedido_id){
            this.$router.push({ name: 'pedidoVenda', params: { id: pedido_id } })
        },
        setQuantidadePorPagina(quantidade) {
            this.quantidadePorPagina = quantidade;
            this.listarProdutos();
        },
        paginaAtual(pagina) {
            this.pagina = pagina;
            // this.primeiroItemDaPagina = pagina * this.quantidadePorPagina + 1 - this.quantidadePorPagina;
            this.listarProdutos();
        },
        proximaPagina() {
            if (this.quantidadePaginas > this.pagina)
                this.paginaAtual(this.pagina + 1);
                this.listarProdutos();
        },
        anteriorPagina() {
            if (this.pagina > 1) {
                this.paginaAtual(this.pagina - 1);
                this.listarProdutos();
            }
        },
        primeiraPagina() {
            this.paginaAtual(1);
            this.listarProdutos();
        },
        ultimaPagina() {
            this.paginaAtual(this.quantidadePaginas);
            this.listarProdutos();
        },
        adicionarProduto() {
            this.$toast.add({ severity: "success", summary: "Sucesso", detail: "Produto Adicionado", life: 3000 });
            this.limparFormProduto();
        },
        limparFormProduto() {
            for (let field in this.produtosAdicionar) {
                this.produtosAdicionar[field] = "";
            }
            this.mostrarAdicionar = false;
        },
        fecharStatus(){
            this.displayStatus = false;
        },
        buscarPorStatus(status) {
            this.api.listarPedidosPorStatus(this.token, this.empresa_id, status, this.pagina, this.quantidadePorPagina, this.ordenar, this.tipoOrdenar).then(data => {
                console.log(data.data.resposta)
                this.state.pedidos = data.data.resposta
                this.quantidadePaginas = parseInt(this.totalLinhas) / parseInt(this.quantidadePorPagina)
                this.quantidadePaginas = Math.ceil(this.quantidadePaginas)
                this.loading = false;
            }).catch((error) => {
                if (error.response.status == 401) {
                    alert(this.msg_fim_sessao);
                    this.$router.push('/login');
                } else {
                    alert(this.msg_erro);
                }
            }); 
        },

        dataAtualFormatada(){
            var data = new Date(),
            dia  = data.getDate().toString(),
            diaF = (dia.length == 1) ? '0'+dia : dia,
            mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
            mesF = (mes.length == 1) ? '0'+mes : mes,
            anoF = data.getFullYear();
            return diaF+"/"+mesF+"/"+anoF;
        }
    },

    components: { Loading }
}
</script>

<style scoped lang="scss">
@import '../assets/demo/badges.scss';
</style>
<style scoped lang="css">
input{
    margin: 10px;
}
select{
    width:100%;
    padding: 10px;
    background-color:#fff;
    border: #ccc solid 1px;
    border-radius:5px;
}
.titulo_pedidos{
    display: inline-block; 
    width: 50%;
}
.botoes_topo{
    margin-bottom: 15px;
}
.teste{
    float: right;
}

thead{
    background-color:#eff6ff;
    border-bottom: #ebeef3 solid 1px;
    width: 100%;
}
td{
    text-align: start;
    padding: 3px 10px; 
    border-bottom: #ebeef3 1px solid; 
    width:10%
}
th{
    text-align: start;
    padding: 5px 10px; 
    font-weight:normal; 
    border: #ebeef3 solid 1px
}

.btn_success{
    background-color:#4e87ee; 
    border:none; 
    margin-right:10px;
}
.btn_success:hover{
    background-color:#4e87ee; 
    border:none; 
    margin-right:10px;
}

.btn_cancel{
    background-color:#9d9d9d; 
    border:none;
    width:30%;
}
.btn_cancel:hover{
    background-color:#9d9d9d; 
    border:none;
    width:30%;
}

.img_marketplace{
    float: left;
    border-radius: 50%;
    width:35px;
    height:35px;
    border: #ccc solid 1px;
    padding:5px;
    margin-right:5px;
    margin-top:-5px;
}
.marketplace{
    background-color: #fff;
    margin-left:auto;
    margin-right:auto;
    text-align:center;
    width:70%;
    border: #ededed solid 1px;
    padding:15px;
    margin-bottom:10px;
    cursor: pointer;
}
.corpo_importar_pedidos{
    width:500px;
    min-height:100px;
}
.nome_marketplace{
    margin-left:10px;
    font-size:18pt;
    margin-top:3px;
}
.inputFile{
    width: 100%;
    height: 60px;
    position:relative;
    overflow: hidden;
    border-bottom: #000 solid 1px;
}

.inputFile input{
    opacity: 0;
    filter: alpha(opacity=0);
    right:0;
    padding-top: 5px;
    z-index: 2;
    height: 80px;
    font-size:100px;
}
.btn_fundo_branco{
    background-color: #fff;
    border: none;
}
.icone{
    width: 30px;
}
button{
    margin-top: 0;
}
</style>
